import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import SEO from "../../components/seo"
import TitleBanner from "../../components/titleBanner/TitleBanner"
import {
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "reactstrap"

const Forms = () => {
  const StyledListGroup = styled(ListGroup)`
    display: flex;
    flex-direction: column;
    margin: 3em;
    align-items: center;
    list-style-type: none;
  `

  const StyledLink = styled(Link)`
    font-size: 1.4em;
    color: black;
    :hover {
      color: #007bff;
    }
  `

  const StyledItem = styled(ListGroupItem)`
    font-size: 0.8em;
    padding: 1em;
  `
  return (
    <>
      <TitleBanner
        title={`Employee Forms`}
        withOnlyDescription={true}
      />
      <SEO />
      <Container className="pt-4">
        <Row className>
          <Col sm="12">
            <StyledListGroup>
              <StyledItem>
                <StyledLink to="https://acegroupwebcdn.blob.core.windows.net/acegroups3cdn/inet/docs/employee/Employee Handbook_V1.1.pdf">
                  Employee Handbook
                </StyledLink>
              </StyledItem>
              <StyledItem>
                <StyledLink to="https://acegroupwebcdn.blob.core.windows.net/acegroups3cdn/inet/docs/employee/Absence_Notification_Form.pdf">
                  Absence Notification Form
                </StyledLink>
              </StyledItem>
              <StyledItem>
                <StyledLink to="https://acegroupwebcdn.blob.core.windows.net/acegroups3cdn/inet/docs/employee/Onsite_Evaluation_Form.docx">
                  Onsite Evaluation Form
                </StyledLink>
              </StyledItem>
              <StyledItem>
                <StyledLink to="https://acegroupwebcdn.blob.core.windows.net/acegroups3cdn/inet/docs/employee/Personal_Information_Update.doc">
                  Personal Info Update
                </StyledLink>
              </StyledItem>
              <StyledItem>
                <StyledLink to="https://acegroupwebcdn.blob.core.windows.net/acegroups3cdn/inet/docs/employee/Reimbursement Form.xlsx">
                  Reimbursement Form
                </StyledLink>
              </StyledItem>
              <StyledItem>
                <StyledLink to="https://acegroupwebcdn.blob.core.windows.net/acegroups3cdn/inet/docs/employee/Timesheet_Form.xls">
                  Timesheet Form
                </StyledLink>
              </StyledItem>
              <StyledItem>
                <StyledLink to="https://acegroupwebcdn.blob.core.windows.net/acegroups3cdn/inet/docs/employee/Overtime_Claim_Form.xlsx">
                  Overtime Claim Form
                </StyledLink>
              </StyledItem>
            </StyledListGroup>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Forms
